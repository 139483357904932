import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../redux/actions";
import softwareIntersectionAPI from "../../redux/actions/softwareIntersectionActions";
import customConsole from "../../config/customConsole";

function SoftwareModuleTables({
  selectedMainSWModuleDetails,
  softwareIntersectionDetails,
  handleSWModuleLink,
  handleSWModuleUnLink,
  selectedEEMainId,
  selectedProjectId,
}) {
  const [filteredSWModules, setFilteredSWModules] = useState([]);

  useEffect(() => {
    customConsole({
      softwareIntersectionDetailsNNNNNNNNNNNN: softwareIntersectionDetails,
    });
  }, [softwareIntersectionDetails]);

  // useEffect(() => {
  //   const filteredList = softwareIntersectionDetails?.find(
  //     (data) => data?.swm_id === selectedMainSWModuleDetails?.swm_id
  //   );
  //   console.log("filteredList", filteredList);
  //   setFilteredSWModules(filteredList);
  // }, [selectedMainSWModuleDetails]);

  return (
    <div>
      <Paper
        elevation={24}
        sx={{
          mt: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          border: "1px solid black", // Add border here
        }}
      >
        {softwareIntersectionDetails && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: 2,
              mt: "20px",
              padding: "10px",
              // Add border here
            }}
          >
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Selected S/W Module Name
              </Typography>
              <strong>:</strong>
              <Typography>{softwareIntersectionDetails[0]?.name}</Typography>
            </Paper>
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Version</Typography>
              <strong>:</strong>
              <Typography>
                {" "}
                {softwareIntersectionDetails[0]?.version}
              </Typography>
            </Paper>
          </Box>
        )}

        <TableContainer
          component={Paper}
          sx={{
            mt: "20px",
            maxHeight: "300px",
            overflowY: "auto",
            border: "1px solid black",
          }} // Add border here
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "2px solid black" }}>
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell sx={{ border: "2px solid black" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Modules
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "2px solid black" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    EE Component
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "2px solid black" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Zone</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {softwareIntersectionDetails[0]?.linked_swm_details?.length >
              0 ? (
                softwareIntersectionDetails[0]?.linked_swm_details?.map(
                  (linkedModule, linkedIdx) => (
                    <TableRow key={linkedModule?.swm_id}>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Typography>{linkedIdx + 1}</Typography>
                      </TableCell>

                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.name}</Typography>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.version}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Description
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ee_details?.description}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ee_details?.version}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule.zone_details?.zone_name || "N/A"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign="center" sx={{ color: "red" }}>
                    Data Not Found
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default SoftwareModuleTables;
