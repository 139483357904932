/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 15-06-2024
 * @description This is the Project Configurator Page for the Application and This page is  View The details of the project
 */
//Importing the required components/functions from the libraries

import {
  Box,
  Button,
  Paper,
  Stack,
  Chip,
  Grid,
  IconButton,
  Typography,
  Badge,
  Tooltip,
} from "@mui/material";

import styled from "@mui/system/styled";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import appTheme from "../../assets/AppTheme/appTheme";
import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  GetSingleImages,
  HandleApiActions,
} from "../../redux/actions/actionHandler";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";

import UpdateStatus from "./UpdateStatus";
import EditVehicleFunction from "./EditVehicleFunction";
import customConsole from "../../config/customConsole";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { CustomLogsDialog } from "../../components";
import UpdateVehicleStatus from "./UpdateVehicleStatus";
import InfoButton from "../../components/infoButton/InfoButton";
import UpdateDeliveryMarket from "./UpdateDeliveryMarket";

function ViewDialog({ dialogData }) {
  /* -------------- Dialogs -------------- */
  const [openProjectChangeLogsDialog, setOpenProjectChangeLogsDialog] =
    useState(false);
  const [openDeliveryMarket, setOpenDeliveryMarket] = useState(false);

  /* -------------- Project Change Logs -------------- */
  const [projectChangeLogsList, setProjectChangeLogsList] = useState([]);
  const [projectLogsDialogData, setProjectLogsDialogData] = useState({});

  const projectStatus = {
    100: (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            left: 97,
            bottom: 10,
            p: 0,
          }}
        >
          <InfoButton infoKey={"project_status"} color={"grey"} />
        </Box>
        <Chip
          sx={{
            boxShadow: "3",
            fontWeight: "bold",
            borderRadius: "0px 15px 0px 15px",
            backgroundColor: "#c8e8fa",
            color: "black",
          }}
          label={"Active Pre SOP"}
          size="medium"
        />
      </Box>
    ),
    101: (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            left: 38,
            bottom: 14,
          }}
        >
          <InfoButton infoKey={"project_status"} color={"grey"} />
        </Box>
        <Chip
          sx={{
            boxShadow: "3",
            fontWeight: "bold",
            borderRadius: "0px 15px 0px 15px",
            backgroundColor: "#c8e8fa",
            color: "black",
          }}
          label={"Draft"}
          size="medium"
        />
      </Box>
    ),
    200: (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            left: 100,
            bottom: 10,
            p: 0,
          }}
        >
          <InfoButton infoKey={"project_status"} color={"grey"} />
        </Box>
        <Chip
          sx={{
            boxShadow: "3",
            fontWeight: "bold",
            borderRadius: "0px 15px 0px 15px",
            backgroundColor: "#f7f6cd",
            color: "black",
          }}
          label={"Active Post SOP"}
          size="medium"
        />
      </Box>
    ),
    300: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "red",
          color: "white",
        }}
        label={"Deactivated"}
        size="medium"
      />
    ),
    500: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#9af5a2",
          color: "green",
        }}
        label={"Completed"}
        size="medium"
      />
    ),
  };
  //1.Redux
  const dispatch = useDispatch();
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.project_status
  );

  const projectDetailsNew = useSelector((state) => state.projectConfigReducer);

  console.log("projectDetailsNew", projectDetailsNew);

  //2.React useState
  const [boxes, setBoxes] = useState([]);
  console.log("boxes?.created_at", boxes?.created_at);
  const [openDialog, setOpenDialog] = useState(false);
  const [editTradeMark, setEditTradeMark] = useState("");
  const [editVF, setEditVF] = useState([]);
  const [value, setValue] = React.useState("1");
  const [trademarkError, setTradeMarkError] = useState(false);
  const [image, setImage] = useState("");
  const [openVfDialog, setOpenVfDialog] = useState(false);

  const [openStatus, setOpenStatus] = useState(false);
  const [openVehicleStatus, setOpenVehicleStatus] = useState(false);

  //3.React useEffect

  //useEffect for project Details
  const projectNew = async () => {
    try {
      const response = await dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_project,
          params: {
            project_id: dialogData?.project_id,
          },
        })
      );

      console.log("-->response<---");
      setBoxes(response?.data[0]);
      console.log(response?.data[0]);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {
    if (dialogData?.project_id) {
      projectNew();
    }
  }, [dialogData]);

  //useEffect for Edit Vehicle Function
  useEffect(() => {
    customConsole("-- boxes ---");
    customConsole(boxes);

    if (boxes) {
      setEditTradeMark(boxes.trade_mark || "");

      // Check if vehicle_function_details is an array before mapping
      const vehicleFunctionDetails = Array.isArray(
        boxes.vehicle_function_details
      )
        ? boxes.vehicle_function_details.map((vf) => String(vf?.id))
        : []; // Default to empty array if not an array

      setEditVF(vehicleFunctionDetails);

      if (boxes.vehicle_class_details && boxes.vehicle_class_details.length) {
        imageURL({ image: boxes.vehicle_class_details[0].image });
      }
    }
  }, [boxes]);

  //4.Functions

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Function for Show Image
  const imageURL = async (imageDetails) => {
    const resp = await GetSingleImages(imageDetails);
    customConsole("imagesURL RESP: ");
    customConsole(resp);
    setImage(resp);
    return resp;
  };

  //Function for Add Vehicle Function
  const handleClickEdit = () => {
    const reqObj = {
      project_id: parseInt(boxes?.project_id),

      ...(openVfDialog === false ? { trade_mark: editTradeMark } : {}),
      ...(openVfDialog === true
        ? { vehicle_funcs: editVF?.map((str) => parseInt(str)) }
        : {}),

      current_time: moment().format(),
    };
    customConsole("reqObj", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      projectNew();
    });

    setOpenDialog(false);
    setOpenVfDialog(false);
  };

  //Function for show the project status
  const getStatusName = (statusCode) => {
    return projectStatus[statusCode] || "Unknown Status";
  };

  /* Get Project change logs */
  const handleGetProjectChangeLogs = async ({ pageNo, pageSize }) => {
    let apiDetails = projectConfigAPI.get_project_change_logs;

    if (boxes && boxes?.project_id) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(boxes?.project_id),
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(boxes?.project_id),
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setProjectChangeLogsList([]);
      } else {
        setProjectChangeLogsList(resp?.data);
      }
    }
  };

  /* Handle dialogs */
  const handleProjectDialogOpen = (data) => {
    setOpenProjectChangeLogsDialog(true);

    // handleGetProjectChangeLogs();

    setProjectLogsDialogData(data);
  };
  const handleProjectDialogClose = () => {
    setProjectLogsDialogData(null);
    setProjectChangeLogsList([]);

    setOpenProjectChangeLogsDialog(false);
  };

  return (
    <div>
      <Box>
        <Paper elevation={24} sx={{ mt: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              {/* Project details and status */}
              <Box sx={styles.paper}>
                <Typography sx={styles.typography}>Project details</Typography>

                {[
                  {
                    key: "Name",
                    value: boxes?.name || "-",
                    infoKey: "name_of_the_project", // Add infoKey here
                  },
                  {
                    key: "Architecture",
                    value: boxes?.architecture_name || "-",
                    infoKey: "type_of_architecture", // Add infoKey here
                  },
                  {
                    key: "Trademark",
                    value: boxes?.trade_mark || "-",
                    infoKey: "trademark", // No info button for this entry
                  },
                  {
                    key: "Zones",
                    value: boxes?.project_zones?.length || "-",
                    infoKey: null, // No info button for this entry
                  },
                  {
                    key: "EE Components",
                    value: boxes?.ee_ids?.length || "-",
                    infoKey: null, // No info button for this entry
                  },
                  {
                    key: "Status",
                    value: boxes?.status || "-",
                    infoKey: null, // No info button for this entry
                  },
                  {
                    key: "Delivery Market",
                    value: boxes?.delivery_market || "-",
                    infoKey: null, // No info button for this entry
                  },
                ].map((data) => {
                  return (
                    <Box sx={styles.dataBox} ey={data.key}>
                      <Typography sx={styles.dataTypography}>
                        {data.key}
                        {data.infoKey && (
                          <InfoButton
                            infoKey={data.infoKey}
                            color={"grey"}
                            sx={{ ml: 1 }} // Add some margin if needed
                          />
                        )}
                      </Typography>
                      <strong>:</strong>
                      {/* {data.key.toLowerCase() === "status" ? (
                        <Box sx={styles.dataStatus}>
                          <Typography ml="10px">
                            {getStatusName(boxes?.status)}
                          </Typography>
                          <IconButton
                            sx={{ color: "blue" }}
                            size="small"
                            onClick={() => setOpenStatus(true)}
                          >
                            <EditIcon sx={styles.editIcon} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Typography sx={styles.trademarkTypography}>
                          {data.value}
                          {data.key.toLowerCase() === "trademark" ? (
                            <IconButton
                              sx={{ color: "blue" }}
                              size="small"
                              onClick={() => setOpenDialog(true)}
                            >
                              <EditIcon sx={styles.editIcon} />
                            </IconButton>
                          ) : undefined}
                        </Typography>
                      )} */}
                      {data.key.toLowerCase() === "status" ? (
                        <Box sx={styles.dataStatus}>
                          <Typography ml="10px">
                            {getStatusName(boxes?.status)}
                          </Typography>
                          <IconButton
                            sx={{ color: "blue" }}
                            size="small"
                            onClick={() => setOpenStatus(true)}
                          >
                            <EditIcon sx={styles.editIcon} />
                          </IconButton>
                        </Box>
                      ) : data.key.toLowerCase() === "delivery market" ? (
                        <Box sx={styles.dataStatus}>
                          <Typography ml="10px">{data.value}</Typography>
                          <IconButton
                            sx={{ color: "blue" }}
                            size="small"
                            onClick={() => setOpenDeliveryMarket(true)} // Set a state for Delivery Market
                          >
                            <EditIcon sx={styles.editIcon} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Typography sx={styles.trademarkTypography}>
                          {data.value}
                          {data.key.toLowerCase() === "trademark" ? (
                            <IconButton
                              sx={{ color: "blue" }}
                              size="small"
                              onClick={() => setOpenDialog(true)}
                            >
                              <EditIcon sx={styles.editIcon} />
                            </IconButton>
                          ) : undefined}
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Grid>

            {/* Project Vehicle details & its Status */}
            <Grid item xs={12} sm={4}>
              <Box elevation={12} sx={styles.vehicleDetailsPaper}>
                <Typography sx={styles.vehicleDetailsTypography}>
                  Vehicle details
                </Typography>
                {[
                  {
                    key: "Platform",
                    value: boxes?.vehicle_platform || "-",
                    infoKey: "vehicle_platform",
                  },

                  {
                    key: "Name",
                    infoKey: null,
                    value: boxes?.vehicle_class_details?.[0]?.name || [],
                  },
                  {
                    key: "Class",
                    infoKey: "vehicle_class",
                    value: boxes?.vehicle_class_details?.[0]?.class || [],
                  },
                  {
                    key: "Sub-Class",
                    infoKey: null,
                    value: boxes?.vehicle_class_details?.[0]?.sub_class || [],
                  },
                  {
                    key: "Status",
                    value: boxes?.vehicle_status || "-",
                    infoKey: "vehicle_status",
                  },
                ].map((data) => {
                  return (
                    <Box sx={styles.dataBox} key={data.key}>
                      <Typography sx={styles.dataTypography}>
                        {data.key}
                        {data.infoKey && (
                          <InfoButton infoKey={data.infoKey} color={"grey"} />
                        )}
                      </Typography>
                      <strong>:</strong>
                      {data.key.toLowerCase() === "status" ? (
                        <Box sx={styles.dataStatus}>
                          <Typography fontWeight="10px" ml="10px">
                            {boxes?.vehicle_status}
                          </Typography>
                          <IconButton
                            sx={{ color: "blue" }}
                            size="small"
                            onClick={() => setOpenVehicleStatus(true)}
                          >
                            <EditIcon sx={styles.editIcon} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Typography sx={styles.trademarkTypography}>
                          {data.value}
                          {data.key.toLowerCase() === "trademark" ? (
                            <IconButton
                              sx={{ color: "blue" }}
                              size="small"
                              onClick={() => setOpenDialog(true)}
                            >
                              <EditIcon sx={styles.editIcon} />
                            </IconButton>
                          ) : undefined}
                        </Typography>
                      )}
                    </Box>
                  );
                })}
                {/* Vehicle Image */}
                <Box sx={styles.vehicleImageBox}>
                  <img src={image} style={styles.imageBox} alt="vehicle pic!" />
                </Box>
              </Box>
            </Grid>
            {/* Other Details like Mft Details, created at, timezone, country, etc.. */}
            <Grid item xs={12} sm={4}>
              <Box elevation={12} sx={styles.otherDetailsPaper}>
                <Typography sx={styles.otherDetailsTypography}>
                  Other details
                </Typography>
                {[
                  {
                    key: "Manufacturer Name",
                    value: boxes?.mft_details?.name || "-",
                    infoKey: "manufacturer_name",
                  },
                  {
                    key: "Manufacturer Address",
                    value: boxes?.mft_details?.address || "-",
                    infoKey: "manufacturer_address",
                  },
                  {
                    key: "Project Created at",

                    value: <DateFormat dateString={boxes?.created_at} /> || "-",
                    infoKey: null,
                  },
                  {
                    key: "Last updated at",
                    value: <DateFormat dateString={boxes?.updated_at} /> || "-",
                    infoKey: null,
                  },
                  {
                    key: "Country",
                    value: boxes?.country || "-",
                    infoKey: null,
                  },
                  {
                    key: "Timezone",
                    value: boxes?.region || "-",
                    infoKey: null,
                  },
                ].map((data) => {
                  return (
                    <Box sx={styles.otherBox} key={data.key}>
                      <Typography sx={styles.otherTypography}>
                        {data.key}
                        {data.infoKey && (
                          <InfoButton infoKey={data.infoKey} color={"grey"} />
                        )}
                      </Typography>
                      <strong>:</strong>
                      <Typography sx={styles.otherValue}>
                        {data.value}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={3}>
          <Box sx={styles.tabsBox}>
            <TabContext value={value} variant="scrollable">
              <Box sx={styles.tabInBox}>
                <StyledTabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                >
                  {boxes?.project_zones && boxes?.project_zones.length && (
                    <StyledTab
                      label={
                        <Badge
                          badgeContent={boxes?.project_zones?.length || "0"}
                          color="primary"
                        >
                          Zones
                        </Badge>
                      }
                      value="2"
                    />
                  )}
                  <StyledTab
                    label={
                      <Badge
                        badgeContent={
                          boxes?.vehicle_function_details?.filter(
                            (item) =>
                              item.id &&
                              item.name &&
                              Object.keys(item).length > 0
                          ).length || 0
                        }
                        color="primary"
                        sx={{ alignItems: "center" }}
                      >
                        Vehicle Function
                        <InfoButton
                          infoKey={"vehicle_function"}
                          color={"grey"}
                        />
                      </Badge>
                    }
                    value="1"
                  />
                  <StyledTab
                    label={
                      <Badge
                        badgeContent={
                          boxes?.powertrain_class_details?.filter(
                            (item) =>
                              item.id &&
                              item.name &&
                              Object.keys(item)?.length > 0
                          ).length || 0
                        }
                        color="primary"
                        sx={{ alignItems: "center" }}
                      >
                        Power Train Class
                        <InfoButton
                          infoKey={"power_train_class"}
                          color={"grey"}
                        />
                      </Badge>
                    }
                    value="3"
                  />
                  <StyledTab
                    label={
                      <Badge
                        badgeContent={
                          boxes?.owner_details?.filter(
                            (item) => Object.keys(item).length > 0
                          ).length || 0
                        }
                        color="primary"
                      >
                        Project Owner
                      </Badge>
                    }
                    value="4"
                  />
                  <StyledTab
                    label={
                      <Badge
                        badgeContent={
                          boxes?.co_owner_details?.filter(
                            (item) => Object.keys(item).length > 0
                          ).length || 0
                        }
                        color="primary"
                      >
                        Co-Owners
                      </Badge>
                    }
                    value="5"
                  />
                  <StyledTab
                    label={
                      <Badge
                        badgeContent={
                          boxes?.edit_user_details?.filter(
                            (item) => Object.keys(item).length > 0
                          ).length || 0
                        }
                        color="primary"
                      >
                        Editors
                      </Badge>
                    }
                    value="6"
                  />
                  <StyledTab
                    label={
                      <Badge
                        badgeContent={
                          boxes?.view_user_details?.filter(
                            (item) => Object.keys(item).length > 0
                          ).length || 0
                        }
                        color="primary"
                      >
                        Viewers
                      </Badge>
                    }
                    value="7"
                  />
                </StyledTabs>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Project Logs">
                    <IconButton
                      color="info"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleProjectDialogOpen(boxes);
                      }}
                    >
                      <HistoryEduIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <TabPanel value="2">
                {boxes?.project_zones && boxes?.project_zones?.length ? (
                  <Box sx={styles.tab2Box}>
                    {boxes?.project_zones?.map((data) => {
                      return (
                        <Paper elevation={12} sx={styles.tab2Paper}>
                          <Typography sx={styles.tab2Typography}>
                            &nbsp;{data?.position}&nbsp;&nbsp;-&nbsp;&nbsp;
                            {data?.zone_name}&nbsp;
                          </Typography>
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography>No Zones</Typography>
                )}
              </TabPanel>
              <TabPanel value="1">
                <Box sx={styles.tab1BoxButton}>
                  <Button
                    size="small"
                    startIcon={<AddIcon />}
                    sx={{
                      ...appTheme.commonBtnStyle,
                    }}
                    variant="outlined"
                    onClick={() => setOpenVfDialog(true)}
                  >
                    Add
                  </Button>
                </Box>
                {openVfDialog && (
                  <EditVehicleFunction
                    openVfDialog={openVfDialog}
                    setOpenVfDialog={setOpenVfDialog}
                    editVF={editVF}
                    setEditVF={setEditVF}
                    handleClickEdit={handleClickEdit}
                  />
                )}
                {boxes?.vehicle_function_details &&
                boxes?.vehicle_function_details?.length ? (
                  <Box sx={styles.tab1Box}>
                    {boxes?.vehicle_function_details?.map((data, idx) => {
                      return (
                        <Paper key={idx} elevation={12} sx={styles.tab1Paper}>
                          <Typography sx={styles.tab1Typo}>
                            {data?.name} - ({data?.abbreviation})
                          </Typography>
                          {data?.other_fields?.description && (
                            <Typography sx={styles.tab1Typo1}>
                              {data?.other_fields?.description}
                            </Typography>
                          )}
                        </Paper>
                      );
                    })}{" "}
                  </Box>
                ) : (
                  <Typography sx={styles.tab1NotFound}>
                    Details not found!
                  </Typography>
                )}
                {/* </Box> */}
              </TabPanel>

              <TabPanel value="3">
                <Box sx={styles.tab3Box}>
                  {boxes?.powertrain_class_details?.length ? (
                    boxes.powertrain_class_details.map((data, idx) => (
                      <>
                        {data.abbreviation ? (
                          <Paper key={idx} elevation={12} sx={styles.tab3Paper}>
                            <Typography sx={styles.tab3Typo}>
                              {data.name} - ({data.abbreviation})
                            </Typography>
                            {data.other_fields?.description && (
                              <Typography sx={styles.tab3Typo1}>
                                {data.other_fields.description}
                              </Typography>
                            )}
                          </Paper>
                        ) : (
                          <Typography sx={styles.tab1NotFound}>
                            Details not found!
                          </Typography>
                        )}
                      </>
                    ))
                  ) : (
                    <Typography sx={styles.tab1NotFound}>
                      Details not found!
                    </Typography>
                  )}
                </Box>
              </TabPanel>

              <TabPanel value="4">
                {" "}
                <Box sx={styles.tab4Box}>
                  <Paper elevation={12} sx={styles.tab4Paper}>
                    {[
                      {
                        key: "Name",
                        value:
                          (boxes?.owner_details &&
                            boxes?.owner_details[0]?.name) ||
                          "-",
                      },
                      {
                        key: "Email",
                        value:
                          (boxes?.owner_details &&
                            boxes?.owner_details[0]?.email) ||
                          "-",
                      },
                    ].map((data) => {
                      return (
                        <Box sx={styles.tab4Box}>
                          <Typography sx={styles.tab4Typo}>
                            {data.key}
                          </Typography>
                          <strong>:</strong>
                          <Typography sx={styles.tab4Typo1}>
                            {data.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Paper>
                </Box>
              </TabPanel>
              <TabPanel value="5">
                {boxes?.co_owner_details &&
                boxes.co_owner_details.length &&
                boxes.co_owner_details[0]?.role_id ? (
                  <Box sx={styles.tab5Box}>
                    {boxes.co_owner_details.map((details, index) => {
                      return (
                        <Paper key={index} elevation={12} sx={styles.tab5Paper}>
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box key={idx} sx={styles.tab5Box1}>
                                <Typography sx={styles.tab5Tpyo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab5Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography sx={styles.tab1NotFound}>
                    Details not found!
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value="6">
                {boxes?.edit_user_details &&
                boxes.edit_user_details.length &&
                boxes.edit_user_details[0]?.role_id ? (
                  <Box sx={styles.tab6Box}>
                    {boxes.edit_user_details.map((details, index) => {
                      return (
                        <Paper key={index} elevation={12} sx={styles.tab6Paper}>
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box key={idx} sx={styles.tab6DataBox}>
                                <Typography sx={styles.tab6Typo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab6Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography sx={styles.tab1NotFound}>
                    Details not found!
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value="7">
                {" "}
                {boxes?.view_user_details &&
                boxes.view_user_details.length &&
                boxes.view_user_details[0]?.role_id ? (
                  <Box sx={styles.tab7Box}>
                    {boxes.view_user_details.map((details, index) => {
                      return (
                        <Paper key={index} elevation={12} sx={styles.tab7Paper}>
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box key={idx} sx={styles.tab7DataBox}>
                                <Typography sx={styles.tab7Typo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab7Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography sx={styles.tab1NotFound}>
                    Details not found!
                  </Typography>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
        {openStatus && (
          <UpdateStatus
            projectNew={projectNew}
            openStatus={openStatus}
            setOpenStatus={setOpenStatus}
            boxes={boxes}
          />
        )}
        {openDeliveryMarket && (
          <UpdateDeliveryMarket
            projectNew={projectNew}
            openDeliveryMarket={openDeliveryMarket}
            setOpenDeliveryMarket={setOpenDeliveryMarket}
            boxes={boxes}
          />
        )}

        {openDialog && (
          <CustomUpdateDialog
            onDisabled={editTradeMark === boxes?.trade_mark ? true : false}
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
            }}
            dialogTitle={"Edit Project"}
            dialogContent={
              <Stack spacing={2}>
                <CustomTextFieldSP
                  size={"small"}
                  label={"Trademark"}
                  placeholder={"Trademark"}
                  value={editTradeMark}
                  setValue={setEditTradeMark}
                  txtFldStyle={{
                    width: "400px",
                  }}
                  validationRules={{ minLength: 3 }}
                  setError={setTradeMarkError}
                  error={trademarkError}
                />
              </Stack>
            }
            onConfirm={handleClickEdit}
          />
        )}
        {openVehicleStatus && (
          <UpdateVehicleStatus
            projectNew={projectNew}
            openVehicleStatus={openVehicleStatus}
            setOpenVehicleStatus={setOpenVehicleStatus}
            boxes={boxes}
          />
        )}
      </Box>

      <CustomLogsDialog
        open={openProjectChangeLogsDialog}
        handleClose={handleProjectDialogClose}
        dialogTitle={"Change Logs"}
        dialogContent={projectChangeLogsList}
        data={projectLogsDialogData}
        getLogsData={handleGetProjectChangeLogs}
      />
    </div>
  );
}

export default ViewDialog;

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "1rem",
    marginRight: theme.spacing(1),

    color: "green",
    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const styles = {
  paper: {
    padding: "10px",
    minHeight: "250px",
    borderRadius: "10px",
  },
  typography: {
    fontWeight: "bold",
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "5px",
  },
  dataBox: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
  },
  dataTypography: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  dataStatus: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  editIcon: { width: "20px", height: "20px" },
  trademarkTypography: {
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "auto",
  },
  vehicleDetailsPaper: {
    padding: "10px",
    minHeight: "250px",
    borderRadius: "10px",
    boxShadow: "10px",
  },
  vehicleDetailsTypography: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "5px",
    textAlign: "center",
  },
  vehicleImageBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  imageBox: { width: 72, height: 72 },
  otherDetailsPaper: {
    padding: "10px",
    minHeight: "250px",
    borderRadius: "10px",
  },
  otherDetailsTypography: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "5px",
    textAlign: "center",
  },
  otherBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,

    alignItems: "start",
  },
  otherTypography: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "155px",
    color: "gray",
  },
  otherValue: {
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "auto",
  },
  tabsBox: { width: "100%", mt: "20px", borderRadius: "10px" },
  tabInBox: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    justifyContent: "space-between",
  },
  tab2Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab2Paper: { height: "30px", padding: "5px" },
  tab2Typography: { fontWeight: "bold", fontSize: "14px" },
  tab1BoxButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tab1Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "10px",
  },
  tab1Paper: { padding: "10px", borderRadius: "10px" },
  tab1Typo: { fontSize: "14px", fontWeight: "bold" },
  tab1Typo1: { fontSize: "12px", fontWeight: "bold" },
  tab1NotFound: { fontWeight: "bold", fontSize: "12px", color: "red" },
  tab3Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab3Paper: { padding: "10px", borderRadius: "10px" },
  tab3Typo: { fontSize: "14px", fontWeight: "bold" },
  tab3Typo1: { fontSize: "12px", fontWeight: "bold" },
  tab4Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab4Paper: { padding: "10px", borderRadius: "10px" },
  tab4Box: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab4Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab4Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab5Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab5Paper: { padding: "10px", borderRadius: "10px" },
  tab5Box1: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab5Tpyo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab5Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab6Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab6Paper: { padding: "10px", borderRadius: "10px" },
  tab6DataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab6Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab6Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab7Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab7Paper: { padding: "10px", borderRadius: "10px" },
  tab7DataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab7Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab7Typo1: { fontSize: "14px", fontWeight: "bold" },
};

const DateFormat = ({ dateString }) => {
  // Format the date using moment.js
  const formattedDate = moment(dateString)
    .utc()
    .format("MMMM DD, YYYY, h:mm A");

  return <div>{formattedDate} UTC</div>;
};
